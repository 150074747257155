<template>
    <!-- 메인 -->
    <div class="admin-content">
        <h3>게시판 관리</h3>
        <div class="flex-wrap right">
            <div class="ml10">
                <div class="btn-wrap">
                    <button class="btn-box" @click="methods.getBoardInfo">조회</button>
                    <button class="btn-box green" @click="{
                        state.dialog.isOpen = true;
                        state.dialog.isNew = true;
                    }" >신규</button>
                </div>
            </div>
            <div>
				<div class="sch-wrap3">
					<div>
						<label>계정 유형</label>
						<div>
                            <div class="select-box">
							    <select v-model="state.dataTable.selectedSystemCode">
                                    <option v-for="item in state.selectBox.items" v-bind:value="item.CodeValue" v-bind:key="item" v-text="item.CodeName" />
                                </select>
                            </div>
						</div>
					</div>
				</div>
			</div>
        </div>

        <!-- 총 개수 -->
        <div class="total-box">
            <p>Total <strong>{{state.dataTable.boardItems === null ? 0 : state.dataTable.boardItems.length}}</strong></p>
        </div>

        <DataTable class="p-datatable-sm"
            columnResizeMode="fit"
            scrollHeight="497px"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :value="state.dataTable.boardItems"
            :paginator="true" 
            :rowHover="true"
            :rows="15"
            :resizableColumns="true" 
            @row-click="eventHandler.dataTable.onRowClick">
            
            <template #empty>
                데이터가 없습니다.
            </template>

            <Column field="OrderSeq" header="순번" :sortable="true" style="text-align:center; width:50px;" headerClass="tc"></Column>
            <Column field="BoardCategory" header="게시판코드" :sortable="true" style="width:100px;" headerClass="tc"></Column>
            <Column field="BoardName" header="게시판명" :sortable="true" style="width:150px;" headerClass="tc"></Column>
            <!-- <Column field="IsReply" header="답글사용" :sortable="true" style="text-align:center; width:60px;"></Column> -->
            <!-- <Column field="IsComment" header="댓들사용" :sortable="true" style="text-align:center; width:60px;"></Column> -->
            <Column field="UseContentImg" header="이미지사용" :sortable="true" style="text-align:center; width:60px;" headerClass="tc"></Column>
            <Column field="UseThumbImg" header="썸네일사용" :sortable="true" style="text-align:center; width:60px;" headerClass="tc"></Column>
            <Column field="CodeName" header="분류코드명" :sortable="true" style="width:100px;" headerClass="tc"></Column>
        </DataTable>

        <Dialog 
            :header="state.dialog.header"
            :visible="state.dialog.isOpen"
            :modal="true"
            @show="eventHandler.dialog.show"
            @hide="eventHandler.dialog.hide"
            @close="state.dialog.isOpen=false"
            @update:visible="state.dialog.isOpen = false">

            <div class="dialog-wrap1 col2">
                <!-- <div>
                    <label>게시판코드 <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" ref="boardCategory" type="text" v-model.trim="state.dataTable.rowDataModel.BoardCategory" :disabled="!state.dialog.isNew" maxlength="12" />
                    </div>
                </div> -->

                <div>
                    <label>게시판코드 <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" ref="boardCategory" type="text" v-model.trim="state.dataTable.rowDataModel.BoardCategory" :disabled="!state.dialog.isNew" maxlength="12" />
                    </div>
                </div>

                <div>
                    <label>게시판명 <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" ref="boardName" type="text" v-model.trim="state.dataTable.rowDataModel.BoardName" maxlength="128" />
                    </div>
                </div>
                <div>
                    <label>이미지사용 <i class="required">*</i></label>
                    <div class="select-box">
                        <select v-model="state.dataTable.rowDataModel.UseContentImg">
                            <option value="Y">Y</option>
                            <option value="N">N</option>
                        </select> 
                    </div>
                </div>
                <div>
                    <label>썸네일사용 <i class="required">*</i></label>
                    <div class="select-box">
                        <select v-model="state.dataTable.rowDataModel.UseThumbImg">
                            <option value="Y">Y</option>
                            <option value="N">N</option>
                        </select> 
                    </div>
                </div>
                <div>
                    <label>최대파일수 <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" ref="attFileCount" type="number" v-model.trim="state.dataTable.rowDataModel.AttFileCount" onkeypress='return event.charCode >= 48 && event.charCode <= 57' />
                    </div>
                </div>
                <div>
                    <label>첨부최대용량 <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" ref="attFileSize" type="number" v-model.trim="state.dataTable.rowDataModel.AttFileSize" onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="12"/>
                    </div>
                </div>
                <div>
                    <label>목록URL <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" ref="listUrl" type="text" v-model.trim="state.dataTable.rowDataModel.ListUrl" maxlength="128" />
                    </div>
                </div>
                <div>
                    <label>게시글URL <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" ref="contentUrl" type="text" v-model.trim="state.dataTable.rowDataModel.ContentUrl" maxlength="128" />
                    </div>
                </div>
                <div>
                    <label>분류코드</label>
                    <div class="select-box">
                        <select v-model="state.dataTable.rowDataModel.Code">
                            <option selected="selected" value="0">==선택==</option>
                            <option v-for="item in state.selectBox.codes" v-bind:value="item.CodeValue" v-bind:key="item" v-text="item.CodeName" />
                        </select>
                    </div>
                </div>
            </div>
            <br>
            <div class="flex-wrap right">
                <div class="ml10">
                    <div class="btn-wrap">
                        <button type="button" class="btn-box gray" v-show="state.dialog.isNew" @click="methods.boardValidation">저장</button>
                        <button type="button" class="btn-box gray" v-show="!state.dialog.isNew" @click="methods.boardValidation">수정</button>
                        <button type="button" class="btn-box red" v-show="!state.dialog.isNew" @click="methods.deleteBoardInfo">삭제</button>
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>
<script>
import { reactive, ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { board } from '@/modules/system/PCM_SM0610E.js';
import { common } from '@/modules/common/common.js';
import { useStore } from 'vuex';
import alertManager from '@/utils/alert-confirm-manager';
export default {
    components: {
    },
    setup() {

        // 스토어
        const store = useStore();

        // 유효성 검사 ref
        const boardCategory = ref(); // 게시판코드
        const boardName = ref(); // 게시판명
        const attFileCount = ref(); // 최대첨부파일수
        const attFileSize = ref(); // 첨부파일최대용량
        const listUrl = ref(); // 게시글목록URL
        const contentUrl = ref(); //게시글URL
    
        // state
        const state = reactive({
            // 데이터 테이블 state
            dataTable: {

                // 게시판 목록
                boardItems: null,

                // 선택된 시스템코드
				selectedSystemCode: "ADM",

                // 행 데이터 모델
                rowDataModel: new Object(),
            },

            dialog: {

                // 다이얼로그 헤더 텍스트
                header: "게시판 관리",

                // 다이얼로그 오픈여부
                isOpen: false,

                // 신규 여부
                isNew: false,
            },

            selectBox: {

                // 계정 유형
                items: new Array(),

                // 분류 코드
                codes: new Array(),
            },

        });

        // 메서드
        const methods = {

            // 게시판 목록 조회
            getBoardInfo: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                board.getBoardInfoList(state.dataTable.selectedSystemCode).then(result => {

                    state.dataTable.boardItems = result;

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                });
            },

            // 게시판 상세 저장
            saveBoardInfo: () => {
                
                // 스토어 값 변경
                store.commit("setLoadingStatus", true);
                
                board.saveBoardInfo(state.dataTable.rowDataModel).then((response) => {

                    if(response.data.success) {
                        
                        alertManager.toast.add();

                        // 목록 재조회
                        methods.getBoardInfo();

                    }
                    
                }).finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                    // 팝업 닫기
                    state.dialog.isOpen = false;

                });
            },

            // 게시판 상세 수정
            updateBoardInfo: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);
                
                board.updateBoardInfo(state.dataTable.rowDataModel).then((response) => {

                    if(response.data.success) {
                        
                        alertManager.toast.save();

                        // 목록 재조회
                        methods.getBoardInfo();

                    }
                    
                }).finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                    // 팝업 닫기
                    state.dialog.isOpen = false;
                    
                });
            },

            // 게시판 상세 삭제
            deleteBoardInfo: () => {
                
                alertManager.confirm.remove().then(() => {
                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);
                    
                    board.deleteBoardInfo(state.dataTable.rowDataModel).then((response) => {

                        if(response.data.success) {

                            alertManager.toast.remove();

                            // 목록 재조회
                            methods.getBoardInfo();

                        }

                    }).finally(() =>{

                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);

                        // 팝업 닫기
                        state.dialog.isOpen = false;

                    });
                });

            },
            // 게시판 정보 유효성검사
            boardValidation: () => {

                let validator = state.dataTable.rowDataModel;
                
                // 게시판코드
                if (!validator.BoardCategory) {

                    boardCategory.value.focus();
                    alertManager.alert.warning('게시판코드를 입력해주세요');
                }
                // 게시판코드
                else if (!common.boardCategoryRegularExpression(validator.BoardCategory)) {
                    // common.boardCategoryRegularExpression 내부 처리
                }
                // 게시판명
                else if (!validator.BoardName) {

                    boardName.value.focus();
                    alertManager.alert.warning('게시판명을 입력해주세요');
                }
                // 최대첨부파일수
                else if (validator.AttFileCount === undefined || validator.AttFileCount === '') {

                    attFileCount.value.focus();
                    alertManager.alert.warning('최대첨부파일수를 입력해주세요');
                }
                // 첨부파일최대용량
                else if (validator.AttFileSize === undefined || validator.AttFileSize === '') {

                    attFileSize.value.focus();
                    alertManager.alert.warning('첨부파일최대용량을 입력해주세요');
                }
                // 게시글목록URL
                else if (!validator.ListUrl) {

                    listUrl.value.focus();
                    alertManager.alert.warning('게시글목록URL을 입력해주세요');
                }// 게시글URL
                else if (!validator.ContentUrl) {

                    contentUrl.value.focus();
                    alertManager.alert.warning('게시글URL을 입력해주세요');
                }
                else {

                    // 게시판 상세 저장
                    if(state.dialog.isNew) alertManager.confirm.save().then(() => { methods.saveBoardInfo() });
                    else alertManager.confirm.edit().then(() => { methods.updateBoardInfo() });
                }
            },
        }

        // 이벤트 핸들러
        const eventHandler = {

            // 데이터 테이블 관련 이벤트 핸들러
            dataTable: {
                
                // 행 클릭 이벤트 처리
                onRowClick: event => {

                    // 행 데이터 모델 설정
                    state.dataTable.rowDataModel = Object.assign({}, event.data);

                    // 팝업 오픈
                    state.dialog.isOpen = true;
                },
            },

            // 다이얼로그 관련 이벤트 핸들러
            dialog: {

                // show 이벤트 처리
                show: () => {
                    
                    // 신규 다이얼로그 모드
                    if(state.dialog.isNew) {

                        //선택한 행 시스템코드 값 가져오기
                        state.dataTable.rowDataModel.SystCode = state.dataTable.selectedSystemCode;

                        //신규 버튼 클릭시 드롭다운 리스트 디폴트값 설정
                        state.dataTable.rowDataModel.IsReply = 'N';
                        state.dataTable.rowDataModel.IsComment = 'N';
                        state.dataTable.rowDataModel.UseContentImg = 'N';
                        state.dataTable.rowDataModel.UseThumbImg = 'N';
                        
                        // 분류 코드 초기화
                        state.dataTable.rowDataModel.Code = '0'
                    }
                },

                // hide 이벤트 처리
                hide: () => {

                    // 행 데이터모델 초기화
                    state.dataTable.rowDataModel = new Object();
                    state.dialog.isNew = false;

                },
            },
        }

        // Dom 객체 그려진 이후
        onMounted(() => {

            // 게시판리스트 초기조회
            methods.getBoardInfo();

            // 시스템 코드 초기조회
            common.getSystemCodeInfo('SYSTEM', '').then(result => state.selectBox.items = result);

            // 분류 코드 초기조회
            common.getSystemCodeInfo('CODEINFO', 'CD019000').then(result => state.selectBox.codes = result);
        });

        return {
            state,
            board,
            methods,
            eventHandler,
            boardCategory,
            boardName,
            attFileCount,
            attFileSize,
            listUrl,
            contentUrl,
        }
    }
}
</script>