import { FoxBizRequest, FoxRestBizClient } from "@/api/client";
import alertManager from '@/utils/alert-confirm-manager.js';

/**
 * 공통 기능 클래스
 */
class Common {

    /**
     * 생성자
     */
    constructor() {
    }

    /**
     * 공통 시스템코드 정보 가져오기
     * @param {string} boardInfo 
     * @param {string} searchVal 
     * @returns 
     */
    getSystemCodeInfo(boardInfo, searchVal) {
        return new Promise((resolve, reject) => {

            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.CodeBiz';
            request.methodId = 'GetCodeInfoByBoardInfo';
            request.parameters.boardInfo = boardInfo
            request.parameters.searchVal = searchVal

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));
        });
    };
    /**
     * # GetCodeInfoByParentCode
     * @param string parentCode 
     * @returns 
     */
    getCodeInfoByParentCode(parentCode) {
        var url = process.env.VUE_APP_BIZ_SERVICE_REST_API, svc = new FoxRestBizClient(url), req = new FoxBizRequest();
        req.classId = "Site.Admin.Biz.CodeBiz", req.methodId = "GetCodeInfoByParentCode", req.parameters.parentCode = parentCode;
        return new Promise((resolve, reject) => {
            svc.execute(req).then((res) => { resolve(res.data.result.value.Table.rows); }).catch((err) => reject(err));
        });
    };

    GetProjectSelect(keyword) {
        return new Promise((resolve, reject) => {

            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Cims.Biz.CimsPrjInfoBiz';
            request.methodId = 'GetProject';
            request.parameters.keyword = keyword

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));
        });
    };

    // 정규식 - RegularExpression
    
    /**
     * #아이디 정규식
     * 
     */
    idRegularExpression(val) {

        if (val.length > 0 && val.length < 5) {

            alertManager.alert.warning('사용자 아이디는 5자 이상 입력해 주시기 바랍니다.');
            return false;
        }
        else if (/[A-Za-z]+/.test(val) == false || /[\d\W]+/.test(val) == false || /[!<>@@#$%^&&*??:;()||_++--=~`'\\]+/.test(val) == true) {

            alertManager.alert.warning('사용자 아이디는 영문자와 숫자 조합으로 입력해 주시기 바랍니다.');
            return false;
        }
        else {

            return true;
        }
    };
    /**
     * #비밀번호 정규식
     * 
     */
    passwordRegularExpression(val) {

        if (val.length > 0 && val.length < 8) {

            alertManager.alert.warning('비밀번호는 8자 이상 입력해 주시기 바랍니다.');
            return false;
        }
        else if (/[A-Za-z]+/.test(val) == false || /[\d\W]+/.test(val) == false || /[!<>@@#$%^&&*??:;()||_++--=~`'\\]+/.test(val) == false) {

            alertManager.alert.warning('비밀번호는 영문자/숫자/특수문자의 조합으로 입력해 주시기 바랍니다.');
            return false;
        }
        else {

            return true;
        }
    };
     /**
     * #게시판 코드 정규식
     * 
     */
      boardCategoryRegularExpression(val) {

        if (val.length > 0 && val.length < 8) {

            alertManager.alert.warning('게시판코드는 8자 이상 입력해 주시기 바랍니다.');
            return false;
        }
        else {

            return true;
        }
    };
}

export const common = new Common();