import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 게시판관리 화면 관련 기능 클래스
 */
class Board {

    /**
     * 생성자
     */
    constructor() {

        this.boardInfo = 'SYSTEM';
        this.searchVal = '';
    }

    /**
     * 게시판 목록 조회
     * @param {string} systemCode
     * @param {string} boardCategory 
     * @returns 
     */
     getBoardInfoList(systCode) {

        return new Promise((resolve, reject) => {
            
            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Common.Biz.BoardInfoBiz';
            request.methodId = 'GetBoardInfo';
            request.parameters.systCode = systCode;
            request.parameters.boardCategory = '';
            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);

            }).catch(error => reject(error));
        });
    }

    /**
     *  게시판 관리 저장
     * @param {Object} boardDetail 
     * @returns 
     */
     saveBoardInfo(boardDetail) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Common.Biz.BoardInfoBiz';
            request.methodId = 'CreateBoardInfo';

            request.parameters = boardDetail;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }

    /**
     *  게시판 관리 수정
     * @param {Object} boardDetail 
     * @returns 
     */
     updateBoardInfo(boardDetail) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Common.Biz.BoardInfoBiz';
            request.methodId = 'UpdateBoardInfo';

            request.parameters = boardDetail;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }

    /**
     *  게시판 관리 삭제
     * @param {Object} boardDetail 
     * @returns 
     */
     deleteBoardInfo(boardDetail) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Common.Biz.BoardInfoBiz';
            request.methodId = 'DeleteBoardInfo';

            request.parameters = boardDetail;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }
}

export const board = new Board();